import useUploadQueue from "./useUploadQueue";
import useUploadState from "./useUploadState";
import {UPLOADING_STATUS} from "../constants";

const getDefaultUploadStates = (files) => {
    const defaultUploadStates = [...new Array(files.length)].map(
        (elem, index) => {
            const defaultUploadState = {
                id: index,
                status: "",
                progress: 0,
                error: null,
            };
            return defaultUploadState;
        }
    );

    return defaultUploadStates;
};

const getWaitingFiles = (files) => {
    const waitingFiles = [...new Array(files.length)].map((elem, index) => {
        return {
            id: index,
        };
    });

    return waitingFiles;
};

const handleDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
};

const handleDragEnter = (e, setIsDragging) => {
    setIsDragging(true);
    handleDefault(e);
};

const handleDragLeave = (e, setIsDragging) => {
    setIsDragging(false);
    handleDefault(e);
};

const handleDrop = (e, status, setIsDragging, addFiles) => {
    setIsDragging(false);
    handleDefault(e);

    if (status !== UPLOADING_STATUS.Loading) {
        addFiles([...e.dataTransfer.files]);
    }
};

export {
    useUploadQueue,
    useUploadState,
    getDefaultUploadStates,
    getWaitingFiles,
    handleDefault,
    handleDragEnter,
    handleDragLeave,
    handleDrop,
};
