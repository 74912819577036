import React, {useEffect, useRef} from "react";
import {styled} from "@mui/material/styles";

const Input = styled("input")({
    display: "none",
});

const FileInput = ({files, selectFiles, ACCEPTED_FILE_TYPES}) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (files.length === 0) {
            // clear input value for correct onChange work
            //inputRef.current.value = "";
        }
    }, [files]);

    return (
        <Input
            type="file"
            style={{display: "none"}}
            id="upload-data"
            name="upload-data"
            onChange={selectFiles}
            inputprops={{
                accept: ACCEPTED_FILE_TYPES.join(", "),
                multiple: true,
            }}
            inputref={inputRef}
        />
    );
};

export {FileInput};
