import React from "react";
import Button from "@mui/material/Button";


const ChooseButton = ({ status, UPLOADING_STATUS }) => {

  return (
    <Button
        style={{
            color: "green",
            marginRight: "10px"
        }}
      variant="outlined"
      size="large"
      component="span"
      disabled={status === UPLOADING_STATUS.Loading}
    >
      Выбрать файлы
    </Button>
  );
};

export { ChooseButton };
