import {APP_KEY} from "shared/constants";
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: false,
    accessToken: null,
    refreshToken: null,
};

export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        login: (state, action) => {
            const {access, refresh} = action.payload;
            state = {
                ...state,
                accessToken: access,
                refreshToken: refresh,
                isAuthenticated: true,
            };
            localStorage.setItem(APP_KEY, JSON.stringify({auth: state}));

            return state;
        },
        refresh: (state, action) => {
            const {access, refresh} = action.payload;
            console.log(access, refresh);
            state = {
                ...state,
                accessToken: access,
                refreshToken: refresh,
                isAuthenticated: true,
            };
            localStorage.setItem(APP_KEY, JSON.stringify({auth: state}));

            return state;
        },
        logout: () => {
            localStorage.setItem(APP_KEY, JSON.stringify({auth: initialState}));

            return initialState;
        },
    },
});

// Action creators are generated for each case reducer function
export const {login, refresh, logout} = authSlice.actions;

export const getAuth = (state) => state.auth;

export default authSlice.reducer;
