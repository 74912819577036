import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faGear} from "@fortawesome/free-solid-svg-icons"
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons"

export const AccordionItem = ({title, info, children}) => {

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className={"accordion-item"} onClick={handleClick}>
            <div className={"accordion-title"}>
                <div>
                    <FontAwesomeIcon icon={info ? faCircleInfo : faGear}/>
                    <span style={{marginLeft: '10px'}}>{title}</span>
                </div>
                <div> {isOpen ? 'v' : '>'} </div>
            </div>
            {isOpen && (<div className={'content'}>{children}</div>)}
        </div>
    )
}
