import React from "react";

import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {useSelector} from "react-redux";
import {getAuth} from "shared/model/redux/auth-reducer";

import Home from "pages/Home/";
import Organizations from "pages/Organizations";
import UploadSlide from "pages/UploadSlide";
import ImageView from "pages/ImageView/";
import Login from "pages/Login";
import Export from "pages/Export";
import PrivateRoute from "components/PrivateRoute";

import "assets/css/main.css";
import Page404 from "../../pages/404";


function App() {

    const {isAuthenticated} = useSelector(getAuth);

    return (
        <Router>
            <Routes>
                <Route
                    path="login"
                    element={<Login isAuthenticated={isAuthenticated}/>}
                />
                <Route
                    path="/"
                    element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                            <Home/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="upload"
                    element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                            <UploadSlide/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path="organizations"
                    element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                            <Organizations/>
                        </PrivateRoute>
                    }
                />
                <Route path="view">
                    <Route
                        path=":slideId"
                        element={
                            <PrivateRoute isAuthenticated={isAuthenticated}>
                                <ImageView/>
                            </PrivateRoute>
                        }
                    />
                </Route>
                <Route
                    path="export"
                    element={
                        <PrivateRoute isAuthenticated={isAuthenticated}>
                            <Export/>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={'*'}
                    element={<Page404/>}
                />
            </Routes>
        </Router>
    );
}

export default App;
