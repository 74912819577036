import React from "react";
import {ThreeCircles} from 'react-loader-spinner';

const Loader = () => {

    return (
        <div style={{
            position: "absolute",
            left: "55%",
            top: "40%",
            zIndex: "1000"
        }}>
            <ThreeCircles
                height="80"
                width="80"
                radius="48"
                color="#1976d2"
                ariaLabel="watch-loading"
                visible={true}
            />
        </div>

    )
}

export default Loader
