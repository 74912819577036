import React from "react";
import {MENU_LEFT} from "../../../shared/routes/constants";
import {AccordionItem} from "./item";
import {Export} from "./export";

const Items = () => {

    return (
        <div className={"accordion"}>
            {MENU_LEFT.map((item, index) => (
                <AccordionItem key={`menu_item_${index}`}
                               title={item.title}
                               info={item.isInfo}
                >{item.content}</AccordionItem>
            ))}
            <AccordionItem title={'Экспорт'}
                           info={false}><Export/></AccordionItem>
        </div>
    )
};

export default Items;
