import {useState, useEffect} from "react";
import {UPLOADING_STATUS, DEFAULT_UPLOAD_QUEUE} from "../constants";

const useUploadQueue = (status) => {
    const [uploadQueue, setUploadQueue] = useState(DEFAULT_UPLOAD_QUEUE);

    useEffect(() => {
        if (status === UPLOADING_STATUS.Loading) {
            const {loading, waiting} = uploadQueue;

            if (loading.length < 3 && waiting.length !== 0) {
                const index = waiting[0].id;

                setUploadQueue((prevState) => {
                    const newWaiting = prevState.waiting.filter(
                        (elem) => elem.id !== index
                    );

                    const newLoading = [...prevState.loading];
                    newLoading.push({id: index});

                    return {
                        ...prevState,
                        loading: newLoading,
                        waiting: newWaiting,
                    };
                });
            }
        }
    }, [uploadQueue, status]);

    return {
        uploadQueue: uploadQueue,
        setUploadQueue: setUploadQueue,
    };
};

export default useUploadQueue;
