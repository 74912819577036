import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {baseApiInstanceWithRefresh} from "../../api/baseApiInstance";
import {SLIDE_URL} from "../../api/urls";

export const fetchSlideById = createAsyncThunk(
    'slide/fetchSlideById',
    (slideId) => {
        return baseApiInstanceWithRefresh.get(`${SLIDE_URL}${slideId}`).then(data => {
            return data.data
        })
    }
)

const initialState = {
    loading: false,
    isError: false,
    data: [],
    displayed: [],
}

export const slideSlice = createSlice({
    name: "slide",
    initialState: initialState,
    reducers: {
        check: (state, action) => {
            state.displayed.splice(action.payload, 0, state.data.tisuues[action.payload])
        },
        checkAll: (state) => {
            state.displayed = state.data.tisuues.slice()
        },
        unCheck: (state, action) => {
            state.displayed = [...state.displayed.slice(0, action.payload), ...state.displayed.slice(action.payload + 1)]
        },
        unCheckAll: (state) => {
            state.displayed = []
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSlideById.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchSlideById.fulfilled, (state, action) => {
                state.loading = false
                state.isError = false
                state.data = action.payload
                state.displayed = action.payload?.tisuues
                state.error = ''
            })
            .addCase(fetchSlideById.rejected, (state) => {
                state.loading = false
                state.isError = true
                state.data = []
            })
    }
})

export const {check, checkAll, unCheck, unCheckAll} = slideSlice.actions
export default slideSlice.reducer


