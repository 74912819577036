import * as React from "react";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import CheckboxWithChilds from "../atoms/CheckboxWithChilds";

export default function OverlaysHandler() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                style={{marginTop: '10px'}}
            >
                Слои
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem>
                    <CheckboxWithChilds
                        type="tissues"
                        label="Ткани"
                        // baseItems={tisuues}
                        // displayedItems={displayedTisuues}
                        // setItems={setDisplayedTisuues}
                    />
                </MenuItem>
                {/*<MenuItem>*/}
                {/*    <CheckboxWithChilds*/}
                {/*        type="annotations"*/}
                {/*        label="Аннотации"*/}
                {/*        // baseItems={annotations}*/}
                {/*        // displayedItems={displayedAnnotations}*/}
                {/*        // setItems={setDisplayedAnnotations}*/}
                {/*    />*/}
                {/*</MenuItem>*/}
            </Menu>
        </div>
    );
}
