import React from "react";
import {useState} from "react";

import Box from "@mui/material/Box";
import Menu from "components/Menu";
import TopBar from "components/TopBar";

const PageWrapper = ({children}) => {
    const [isMenuHidden, setMenuHidden] = useState(false);

    return (
        <Box sx={{display: "flex", alignItems: "flex-start", width: "100%"}}>
            <Menu isMenuHidden={isMenuHidden}/>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: isMenuHidden ? "100%" : "calc(100% - 340px)",
                    marginLeft: isMenuHidden ? 0 : "340px",
                }}
            >
                <TopBar isMenuHidden={isMenuHidden} setMenuHidden={setMenuHidden}/>
                {children}
            </Box>
        </Box>
    );
};

export default PageWrapper;
