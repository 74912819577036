import React from "react";
import {ACCEPTED_FILE_TYPES, UPLOADING_STATUS} from "../constants";
import {FileInput, ChooseButton, ClearButton} from "../atoms";
import {selectFiles, clearAllFiles} from "../lib/fileHandlers";

function FilesControls({files, status, setFiles, setStatus}) {
    return (
        <>
            <label htmlFor="upload-data">
                <FileInput
                    files={files}
                    selectFiles={(e) => selectFiles(e, files, setFiles, setStatus)}
                    ACCEPTED_FILE_TYPES={ACCEPTED_FILE_TYPES}
                />
                <ChooseButton status={status} UPLOADING_STATUS={UPLOADING_STATUS}/>
            </label>
            <ClearButton
                status={status}
                clearAllFiles={clearAllFiles}
                UPLOADING_STATUS={UPLOADING_STATUS}
            />
        </>
    );
}

export default FilesControls;
