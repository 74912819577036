import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function TissueSelect({ tissue, setTissue }) {
  return (
      <Box sx={{ display: "flex", alignItems: "center", padding: 1 }}>
      <Typography  component="h3" variant="h6" draggable={false} sx={{ mr: 1 }}>
        Тип ткани:
      </Typography>
        <FormControl sx={{ m: 1, minWidth: 120 }} >
          <Select
            required
            labelId="tissue-select-label"
            id="tissue-select"
            value={tissue}
            label="Tissue"
            onChange={(event) => setTissue(event.target.value)}
            sx={{ height: 36, minWidth: 200 }}
          >
            <MenuItem value="colon">Толстая кишка</MenuItem>
            <MenuItem value="lung">Легкое</MenuItem>
            <MenuItem value="breast">Молочная железа</MenuItem>
          </Select>
        </FormControl>
    </Box>
  );
}

export default TissueSelect;
