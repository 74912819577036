import React, {useState, useEffect} from "react";
import OpenSeaDragon from "openseadragon";
import "@recogito/annotorious-openseadragon/dist/annotorious.min.css";

import Box from "@mui/material/Box";
import AnnotationsToolPicker from "./atoms/AnnotationsToolPicker";
import OverlaysHandler from "./molecules/OverlaysHandler";

import {initMarks} from "./lib/marks";
import {initAnnotations} from "./lib/annotationsHandler";
import {useSelector} from "react-redux";

const OpenSeaDragonViewer = () => {

    const [viewer, setViewer] = useState(null);
    const [viewer2, setViewer2] = useState(null);
    const [marksOverlay, setMarksOverlay] = useState([]);

    const file = useSelector((store) => store.slide.data.dzi?.file)
    const slideId = useSelector((store) => store.slide.data.dzi?.id)
    const tissues = useSelector((store) => store.slide.displayed)

    const annotations = useSelector((store) => store.slide.data.annotations)
    const [annotationsOverlay, setAnnotationsOverlay] = useState(null);

    const [annotationsDrawingConfig, setAnnotationsDrawingConfig] = useState({
        enabled: false,
        tool: "polygon",
    });

    const InitOpenSeaDragon = () => {
        viewer && viewer.destroy();
        viewer2 && viewer2.destroy();
        const seaDragon1 = OpenSeaDragon({
            id: "openSeaDragon1",
            showNavigator: true,
            prefixUrl: "/openseadragon/images/",
            tileSources: file,
            animationTime: 0.5,
            blendTime: 0.1,
            constrainDuringPan: true,
            maxZoomPixelRatio: 2,
            minZoomLevel: 1,
            visibilityRatio: 1,
            zoomPerScroll: 2,
            immediateRender: true,
        });
        const seaDragon2 = OpenSeaDragon({
            id: "openSeaDragon2",
            showNavigator: true,
            prefixUrl: "/openseadragon/images/",
            tileSources: file,
            animationTime: 0.5,
            blendTime: 0.1,
            constrainDuringPan: true,
            maxZoomPixelRatio: 2,
            minZoomLevel: 1,
            visibilityRatio: 1,
            zoomPerScroll: 2,
            immediateRender: true,
        });

        let viewer1Leading = false;
        let viewer2Leading = false;

        const viewer1Handler = () => {
            if (viewer2Leading) {
                return;
            }

            viewer1Leading = true;
            seaDragon2.viewport.zoomTo(seaDragon1.viewport.getZoom(), null, true);
            seaDragon2.viewport.panTo(seaDragon1.viewport.getCenter(), true);
            viewer1Leading = false;
        };

        const viewer2Handler = function () {
            if (viewer1Leading) {
                return;
            }

            viewer2Leading = true;
            seaDragon1.viewport.zoomTo(seaDragon2.viewport.getZoom(), null, true);
            seaDragon1.viewport.panTo(seaDragon2.viewport.getCenter(), true);
            viewer2Leading = false;
        };

        seaDragon1.addHandler('zoom', viewer1Handler);
        seaDragon2.addHandler('zoom', viewer2Handler);
        seaDragon1.addHandler('pan', viewer1Handler);
        seaDragon2.addHandler('pan', viewer2Handler);

        setViewer(seaDragon1);
        setViewer2(seaDragon2)
    };

    useEffect(() => {
        InitOpenSeaDragon();
        return () => {
            viewer && viewer.destroy();
        };
    }, []);

    useEffect(() => {
        if (file && viewer) viewer.open(file.source);
    }, [file]);

    useEffect(() => {
        if (viewer && tissues) setMarksOverlay(initMarks(viewer, tissues));
        if (viewer && annotations) setAnnotationsOverlay(initAnnotations(viewer, annotations, slideId));
        return () => {
            marksOverlay && marksOverlay.forEach((markOverlay) => markOverlay.clear());
            annotationsOverlay && annotationsOverlay.forEach((annotationOverlay) => annotationOverlay.destroy());
        };
    }, [viewer]);

    useEffect(() => {
        if (viewer && marksOverlay) InitOpenSeaDragon();
    }, [tissues]);

    useEffect(() => {
        if (viewer && annotationsOverlay) {
            annotationsOverlay.forEach((annotationOverlay) => {
                annotationOverlay.destroy()
            });
            setAnnotationsOverlay(initAnnotations(viewer, annotations, slideId));
        }
    }, [annotations]);

    useEffect(() => {
        if (annotationsOverlay) {
            const ownerAnnotations = annotationsOverlay[1];
            const {enabled, tool} = annotationsDrawingConfig;
            ownerAnnotations.setDrawingEnabled(enabled);
            ownerAnnotations.setDrawingTool(tool);
        }
    }, [annotationsDrawingConfig]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: '10px',
                    marginRight: '20px',
                }}
            >
                <AnnotationsToolPicker
                    annotationsDrawingConfig={annotationsDrawingConfig}
                    setAnnotationsDrawingConfig={setAnnotationsDrawingConfig}
                />
                <OverlaysHandler/>
            </Box>
            <div style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px"
            }}>
                <div
                    id="openSeaDragon2"
                    style={{
                        height: "80vh",
                        width: "50%",
                        border: "1px solid #000",
                        background: "#fff"
                    }}
                />
                <div
                    id="openSeaDragon1"
                    style={{
                        height: "80vh",
                        width: "50%",
                        marginLeft: "10px",
                        border: "1px solid #000",
                        background: "#fff"
                    }}
                />
            </div>
        </>
    );
};

export default OpenSeaDragonViewer;
