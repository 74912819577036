import React from "react";
import PageWrapper from "components/PageWrapper";

function Page404() {
    return (
        <PageWrapper>
            <h1>Страница не существует! Перейдите <a href={"/"}>в начало</a></h1>
        </PageWrapper>
    );
}

export default Page404;
