import React, {useEffect, useState} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";

import {HEADERS} from "../constants";
import {useDispatch, useSelector} from "react-redux";
import {fetchSlidesByPage} from "../../../shared/model/redux/slides-reducer";
import Loader from "./loader";
import {Error} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {SlidesTableRow} from "../atoms/SlidesTableRow";


function TableWithPagination() {

    const dispatch = useDispatch();

    const [requestParams, setRequestParams] = useState({
        page: 1,
        search: '',
    });

    const loading = useSelector((store) => store.slides.loading)
    const isError = useSelector((store) => store.slides.isError)
    const rows = useSelector((store) => store.slides.data.results)
    const total_pages = useSelector((store) => store.slides.data.total_pages)

    useEffect(() => {
        dispatch(fetchSlidesByPage(requestParams));
    }, [dispatch]);


    useEffect(() => {
        let handler = (e) => {
            if (e.key === 'Enter' || e.keyCode === 13) dispatch(fetchSlidesByPage(requestParams));
        }
        document.addEventListener("keydown", handler)
        return () => {
            document.removeEventListener("keydown", handler)
        }
    }, [requestParams])

    const setSearch = (e) => {
        const {value} = e.target;
        setRequestParams({...requestParams, page: 1, search: value});
    };

    const handleChange = (event, value) => {
        setRequestParams({...requestParams, page: value});
    };

    if (loading) return <Loader/>

    if (isError) return <Error/>

    return (
        <>
            <div style={{width: '500px', margin: '0 20px -25px auto'}}>
                <TextField
                    id="search"
                    label="Введите имя слайда для поиска"
                    variant="standard"
                    sx={{width: "97%", margin: 1}}
                    value={requestParams.search}
                    onChange={(e) => setSearch(e)}
                />
            </div>
            <div>
                <Container sx={{padding: "24px"}} maxWidth={false}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><p>{}</p></TableCell>
                                {HEADERS.map((header, index) => (
                                    <TableCell key={index}><span
                                        style={{fontWeight: 'bold'}}>{header}</span></TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row, index) => (
                                <SlidesTableRow row={row} key={index} cls={index % 2 ? '' : 'colored'}/>
                            ))}
                        </TableBody>
                    </Table>
                    <Pagination
                        className={'pagination'}
                        sx={{margin: "12"}}
                        count={total_pages}
                        onChange={handleChange}
                    />
                </Container>
            </div>
        </>
    );
}

export default TableWithPagination;
