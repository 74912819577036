import * as Annotorious from "@recogito/annotorious-openseadragon";
import {ANNOTATION_URL} from "shared/api/urls";

import postApiRequest from "shared/api/postApiRequest";
import deleteApiRequest from "shared/api/deleteApiRequest";
import patchApiRequest from "shared/api/patchApiRequest";

const clearUuid = (id) => {
    return id.replace(/#/g, "");
};

const createAnnotation = async (annotation, slideId) => {
    const uuid = clearUuid(annotation.id);
    const data = {
        uuid: uuid,
        json: annotation,
        slide: slideId,
        user: 1,
    };
    const response = await postApiRequest(ANNOTATION_URL, data);
    console.log(response);
};

const updateAnnotation = async (annotation) => {
    const uuid = clearUuid(annotation.id);
    const url = `${ANNOTATION_URL}${uuid}/`;
    const data = {
        json: annotation,
    };
    const response = await patchApiRequest(url, data);
    console.log(response);
};

const deleteAnnotation = async (annotation) => {
    const uuid = clearUuid(annotation.id);
    const url = `${ANNOTATION_URL}${uuid}/`;
    const response = await deleteApiRequest(url);
    console.log(response);
};

const initOwnerAnnotorious = (viewer, config, slideId) => {
    const anno = Annotorious(viewer, config);

    anno.on("createAnnotation", function (annotation) {
        createAnnotation(annotation, slideId);
    });

    anno.on("updateAnnotation", function (annotation) {
        updateAnnotation(annotation);
    });

    anno.on("deleteAnnotation", function (annotation) {
        deleteAnnotation(annotation);
    });

    return anno;
};

const initAnnotations = (viewer, annotations, slideId) => {
    const ownerConfig = {locale: "ru"};
    const readOnlyConfig = {...ownerConfig, readOnly: true};

    let ownerAnnotations = [];
    let readOnlyAnnotations = [];

    annotations.forEach((annotation) => {
        if (annotation.is_owner) {
            ownerAnnotations.push(annotation);
        } else {
            readOnlyAnnotations.push(annotation);
        }
    });

    const readOnlyAnno = Annotorious(viewer, readOnlyConfig);
    const ownerAnno = initOwnerAnnotorious(viewer, ownerConfig, slideId);

    readOnlyAnnotations.forEach((annotation) => {
        readOnlyAnno.addAnnotation(annotation.json);
    });

    ownerAnnotations.forEach((annotation) => {
        ownerAnno.addAnnotation(annotation.json);
    });

    return [readOnlyAnno, ownerAnno];
};

export {initAnnotations};
