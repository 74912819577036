import React from 'react';
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

function OrganizationSelect({organization, organizations, setOrganization}) {
    const handleOrganizationChange = (event) => {
        setOrganization(event.target.value);
    };

    return (
        <Box sx={{display: "flex", alignItems: "center", justifyItems: "center", padding: 1}}>
            <Typography component="h3" variant="h6" draggable={false} sx={{mr: 1}}>
                Организация:
            </Typography>

            <FormControl sx={{m: 1, minWidth: 200}} error={!organization} style={{marginTop: '30px'}}>
                <Select
                    required
                    labelId="organization-label"
                    id="organization"
                    value={organization}
                    onChange={handleOrganizationChange}
                    sx={{height: 36, minWidth: 200}}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {organizations.map((organization) => (
                        <MenuItem key={organization.id} value={organization.code}>
                            {organization.name}
                        </MenuItem>

                    ))}
                </Select>
                {!organization && <FormHelperText>не заполненно</FormHelperText>}
            </FormControl>
        </Box>
    );
}

export default OrganizationSelect;
