import React from "react";

import PageWrapper from "components/PageWrapper";
import TableWithPagination from "./molecules/TableWithPagination";

import "assets/css/home.css";

export function Home() {
    return (
        <PageWrapper>
            <TableWithPagination/>
        </PageWrapper>
    );
}
