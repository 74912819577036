import React, {useEffect, useState} from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addSelected, removeSelected} from "../../../shared/model/redux/slides-reducer";


export const SlidesTableRow = ({row, cls}) => {

    const dispatch = useDispatch();
    const reset = useSelector((store) => store.slides.reset);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(false)
    }, [reset])

    const handleChange = (item) => {
        setChecked(!checked)
        if (!checked) {
            dispatch(addSelected(item));
        } else {
            dispatch(removeSelected(item.id))
        }
    }

    return (
        <TableRow className={cls}>

            <TableCell>
                <Checkbox checked={checked}
                          onChange={() => handleChange(row)}/>
            </TableCell>
            <TableCell component="th" scope="row">
                {row.organization}
            </TableCell>
            <TableCell>{row.icd_10}</TableCell>
            <TableCell><p>{}</p></TableCell>
            <TableCell><p>{row.pathology}</p></TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>
                {row.dzi ? (
                    <NavLink to={`/view/${row.id}/`} target='_blank'><button className={'openButton'}>Открыть</button></NavLink>
                ) : null}
            </TableCell>
            <TableCell>{row.processing_date}</TableCell>
            <TableCell><p>{row.status === true ? 'Готово' : 'Не готово'}</p></TableCell>
        </TableRow>
    );
}
