import React from 'react';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {SLIDE_URL} from "shared/api/urls";
import {FileListItem} from "../atoms";
import {useUploadQueue, useUploadState} from "../lib";
import {uploadFilesHandler} from "../lib/fileHandlers";

function FilesList({
                       files,
                       status,
                       icd10,
                       tissue,
                       typeObj,
                       versionModel,
                       organization,
                       isLoadingStarted,
                       setFiles,
                       setStatus,
                   }) {
    const {uploadQueue, setUploadQueue} = useUploadQueue(status);
    const {uploadState, setUploadState} = useUploadState(
        uploadQueue,
        setStatus
    );

    useEffect(() => {
        if (isLoadingStarted) {
            uploadFilesHandler(files, setUploadState, setUploadQueue, setStatus);
        }
    }, [isLoadingStarted]);

    return (
        <Box sx={{p: 1}}>
            <Typography component="h3" variant="h6" draggable={false}>
                Выбранные файлы:
            </Typography>
            {files && (
                <List>
                    {files.map((file, index) => (
                        <FileListItem
                            url={SLIDE_URL}
                            key={index}
                            file={file}
                            index={index}
                            files={files}
                            status={status}
                            icd10={icd10}
                            organization={organization}
                            tissue={tissue}
                            typeObj={typeObj}
                            versionModel={versionModel}
                            setFiles={setFiles}
                            setStatus={setStatus}
                            uploadState={uploadState}
                            setUploadState={setUploadState}
                            uploadQueue={uploadQueue}
                            setUploadQueue={setUploadQueue}
                        />
                    ))}
                </List>
            )}
        </Box>
    );
}

export default FilesList;
