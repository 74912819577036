import { dgxApiInstance } from "./baseApiInstance";

const postRequestWithLocalStorage = async (url, data = {}, config = {}) => {
    try {
        const response = await dgxApiInstance.post(url, data, config);

        if (response?.data?.id_task) {
            localStorage.setItem("id_task", response.data.id_task);
        }

        return response;
    } catch (error) {
        return error;
    }
};

export default postRequestWithLocalStorage;
