import React from "react";

import Box from "@mui/material/Box";

import ExportSlidesModal from "./molecules/ExportSlidesModal";
import PageWrapper from "components/PageWrapper";

const StyleCap = {
    textAlign: "center",
    marginTop: "17px",
    marginBottom: "15px"
}

function Export() {
    return (
        <PageWrapper>
            <div className="Cap" style={StyleCap}> Добавить слайд в обработку</div>
            <Box sx={{width: "70%", margin: "0 auto"}}>
                <ExportSlidesModal/>
            </Box>
        </PageWrapper>
    );
}

export default Export;
