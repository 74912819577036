import React from 'react';
import Header from "./atoms/Header";
import Items from "./molecules/Items";

import "assets/css/menu.css";


export function Menu({isMenuHidden}) {

    return (
        <aside style={{visibility: isMenuHidden ? "hidden" : null}}>
            <Header/>
            <Items/>
        </aside>
    );
}
