import CanvasOverlayHd from "shared/lib/canvas-overlay-hd";

import {drawGeoJson} from "./geoJsonDrawer";

const initMarks = (viewer, tissues) => {

    let marks = [];

    tissues.forEach((tissue) => {
        const mark = new CanvasOverlayHd(viewer, {

            onRedraw: function (opts) {
                console.log('Redraw Called!')
                const {context: ctx} = opts;
                drawGeoJson(ctx, tissue.geojson);
            },
        });

        // render overlay after filtering marks
        mark.resize();
        mark.updateCanvas();

        marks.push(mark);
    });

    return marks;
};

const testMarks = (marks) => {
    marks.pop()
    return marks
}


export {initMarks, testMarks};
