const drawGeoJson = (ctx, geoJsonData) => {
    geoJsonData.forEach((feature) => {
        if (feature.geometry.type === "Point") {
            drawPoint(ctx, feature.geometry.coordinates, feature.properties);
        }
        if (feature.geometry.coordinates.length > 0) {
            if (feature.geometry.type === "Polygon") {
                drawPolygon(ctx, feature.geometry.coordinates[0], feature.properties);
            }
            if (feature.geometry.type === "MultiPolygon") {
                feature.geometry.coordinates.forEach((coords) =>
                    drawPolygon(ctx, coords[0], feature.properties)
                );
            }
        }
    });
};

const drawPolygon = (ctx, coords, properties) => {
    const coordsCopy = [...coords];
    const initialPoint = coordsCopy.shift();
    ctx.fillStyle = properties.fillColor;//"rgba(0, 255, 0, 1)";
    ctx.globalAlpha = properties.fillOpacity;
    ctx.beginPath();

    ctx.moveTo(initialPoint[0], initialPoint[1]);
    coordsCopy.forEach((point) => ctx.lineTo(point[0], point[1]));

    ctx.closePath();
    ctx.fill();
};

const drawPoint = (ctx, coords, properties) => {
    ctx.beginPath();
    ctx.arc(coords[0], coords[1], properties.radius, 0, 2 * Math.PI, false);
    ctx.fill();
    ctx.stroke();
    ctx.closePath();
};

export {drawGeoJson};
