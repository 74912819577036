import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";

import {HEADERS} from "../constants";

function TableWithPagination({data, setPage}) {
    const {results: rows, total_pages} = data;

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <Container sx={{padding: "24px"}}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {HEADERS.map((header, index) => (
                            <TableCell key={index}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index} className={index % 2 === 0 ? 'colored' : ''}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell>{row.full_name}</TableCell>
                            <TableCell>{row.code}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Pagination
                className={'pagination'}
                sx={{margin: "12"}}
                count={total_pages}
                onChange={handleChange}
            />
        </Container>
    );
}

export default TableWithPagination;
