import React from "react";
import Button from "@mui/material/Button";


const ClearButton = ({clearAllFiles, status, UPLOADING_STATUS}) => {

    return (
        <Button
            color="primary"
            style={{color: "#db3f3f"}}
            variant="outlined"
            size="large"
            onClick={clearAllFiles}
            disabled={status === UPLOADING_STATUS.Loading}
        >
            Очистить
        </Button>
    )
};
export {ClearButton};
