import React from "react";
import {Navigate} from "react-router-dom";

const PrivateRoute = ({
                          isAuthenticated,
                          redirectPath = "/login",
                          children,
                      }) => {
    if (!isAuthenticated) {
        return <Navigate to={redirectPath} replace/>;
    }

    return children;
};

export default PrivateRoute;
