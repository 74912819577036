import React, {useState} from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useDispatch, useSelector} from "react-redux";
import {check, checkAll, unCheck, unCheckAll} from "../../../shared/model/redux/slide-reducer";

//Получение переметров для отрисовки чекбоксов
const getFormParams = (type, item, displayedItems) => {
    let params = {};

    if (type === "tissues") {
        params.label = `${item.name}`;
        params.checked = displayedItems.filter((displayedItem) => displayedItem.id === item.id).length !== 0;
    } else {
        params.label = `${item.employee.first_name}`;
        params.checked =
            displayedItems.filter((displayedItem) => displayedItem.uuid === item.uuid)
                .length !== 0;
    }
    params.clr = item.geojson[0].properties.fillColor;
    return params;
};

export default function CheckboxWithChilds({type, label}) {

    const dispatch = useDispatch();
    const [checked, setChecked] = useState([true, true]);

    const tissues = useSelector((store) => store.slide.data.tisuues)
    const annotations = useSelector((store) => store.slide.data.annotations);
    const baseItems = tissues.concat(annotations)
    const displayedItems = useSelector((store) => store.slide.displayed)


    //Самый верхний чекбокс, управляет сразу группой
    const handleGroup = (event) => {
        const {
            target: {checked},
        } = event;

        if (checked) {
            dispatch(checkAll());
        } else {
            dispatch(unCheckAll());
        }
        setChecked([event.target.checked, event.target.checked]);
    };


    //Управление отдельным чекбоксом
    const updateItem = (event, item, idx) => {
        const {
            target: {checked},
        } = event;
        const index = displayedItems.indexOf(item);

        if (checked) {
            dispatch(check(idx));
        } else {
            dispatch(unCheck(index));
        }
    };

    const children = (
        <Box sx={{display: "flex", flexDirection: "column", ml: 3}}>
            {baseItems.map((item, index) => {
                const {label, checked, clr} = getFormParams(type, item, displayedItems);
                return (
                    <FormControlLabel
                        key={index}
                        label={label}
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={(e) => updateItem(e, item, index)}
                                style={{color: clr}}
                            />
                        }
                    />
                );
            })}
        </Box>
    );

    return (
        <div>
            <FormControlLabel
                label={label}
                control={
                    <Checkbox
                        checked={checked[0] && checked[1]}
                        indeterminate={checked[0] !== checked[1]}
                        onChange={handleGroup}
                    />
                }
            />
            {children}
        </div>
    );
}
