export const ROUTES_TOP = [
    {
        path: "/",
        text: "Cлайды",
    },
    {
        path: "/upload/",
        text: "Обработка",
    },
    {
        path: "/organizations/",
        text: "Организации",
    },
];

export const ROUTES_LEFT = [
    {
        path: "/export/",
        text: "Экспорт",
    },
];

export const MENU_LEFT = [
    {
        title: "О системе",
        content: "ECP PATO Colon 2.0",
        isInfo: true
    },
]
