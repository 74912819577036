import React, {useState, useEffect} from "react";

import TableWithPagination from "./molecules/TableWithPagination";
import useApiGetRequestHook from "shared/api/getRequestHook";
import {ORGANIZATION_URL} from "shared/api/urls";

import "assets/css/home.css";
import PageWrapper from "components/PageWrapper";

export function Organizations() {
    const [url, setUrl] = useState(ORGANIZATION_URL);
    const [page, setPage] = useState(1);
    const [data, setData] = useState({});

    useEffect(() => {
        setUrl(`${ORGANIZATION_URL}?page=${page}`);
    }, [page]);

    const {isCompleted, isSuccessful, isFailed, response} =
        useApiGetRequestHook(url);

    useEffect(() => {
        if (isSuccessful) setData(response.data);
    }, [isCompleted, isSuccessful, response]);

    const dataLength = Object.keys(data).length;

    const StyleCap = {
        textAlign: "center",
        marginTop: "15px",
        fontWeight: 'bold'
    }

    return (

        <PageWrapper>
            <div className="Cap" style={StyleCap}> Справочник организаций</div>
            {isSuccessful && dataLength > 0 && (
                <TableWithPagination data={data} setPage={setPage}/>
            )}
            {isFailed && <h3>Ошибка загрузки</h3>}
        </PageWrapper>
    );
}
