import { useState, useEffect } from "react";
import { baseApiInstanceWithRefresh } from "./baseApiInstance";

const useApiPostRequestWithProgressHook = (url, data, index, updateUploadProgress) => {
    const [result, setResult] = useState({
        isCompleted: false,
        isSuccessful: false,
        isFailed: false,
        response: null,
        error: null,
    });

    useEffect(() => {
        if (data) {
            const config = {
                onUploadProgress: function (progressEvent) {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    updateUploadProgress(index, percentCompleted);
                },
            };

            baseApiInstanceWithRefresh
                .post(url, data, config)
                .then((res) => {
                    setResult((prevState) => ({
                        ...prevState,
                        isCompleted: true,
                        isSuccessful: true,
                        response: res.data,
                    }));
                })
                .catch((error) => {
                    setResult((prevState) => ({
                        ...prevState,
                        isCompleted: true,
                        isFailed: true,
                        error: error,
                    }));
                });
        }
        //eslint-disable-next-line
    }, [data]);

    return result;
};

export default useApiPostRequestWithProgressHook;
