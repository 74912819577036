import {useState, useEffect} from "react";
import {UPLOADING_STATUS} from "../constants";

const useUploadState = (uploadQueue, setStatus) => {
    const [error, setError] = useState("");
    const [uploadState, setUploadState] = useState([]);

    useEffect(() => {
        const uploadStateLength = uploadState.length;
        const {completed} = uploadQueue;

        if (uploadStateLength !== 0 && uploadStateLength === completed.length) {
            if (uploadState.some((state) => state.status === "pending")) {
                if (uploadState.some((state) => state.progress !== 100)) {
                    setStatus(UPLOADING_STATUS.Loading);
                } else {
                    setStatus(UPLOADING_STATUS.Processing);
                }
            }
            if (uploadState.every((state) => state.status === "fulfilled")) {
                setStatus(UPLOADING_STATUS.Success);
            }
            if (uploadState.some((state) => state.error !== null)) {
                const statesWithError = uploadState.filter((state) => state.error);
                if (statesWithError.length !== 0) {
                    setError("Ошибки отображаются рядом с файлами");
                } else {
                    setError(
                        "Попробуйте повторить позднее или обратитесь к системному администратору"
                    );
                }
                setStatus(UPLOADING_STATUS.UploadingError);
            }
        }
    }, [uploadState, uploadQueue]);

    return {
        uploadState: uploadState,
        setUploadState: setUploadState,
        error: error,
    };
};

export default useUploadState;
