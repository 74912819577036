import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import useApiGetRequestHook from "shared/api/getRequestHook";
import { ORGANIZATION_URL } from "shared/api/urls";
import { UPLOADING_STATUS } from "./constants";
import OrganizationSelect from "./molecules/OrganizationSelect";
import Icd10Modal from "./molecules/Icd10Modal";
import FilesUpload from "./molecules/FilesUpload";
import PageWrapper from "components/PageWrapper";
import TissueSelect from "./molecules/TissueSelect";

function UploadSlide() {
    const [organization, setOrganization] = useState("");
    const [organizations, setOrganizations] = useState([]);
    const [tissue, setTissue] = useState("");
    const [icd10, setIcd10] = useState({});
    const [status, setStatus] = useState(UPLOADING_STATUS.Initialization);

    const typeObj = "qupath"; // Значение для type_obj
    const versionModel = "1"; // Значение для version_model

    const {
        isCompleted,
        isSuccessful,
        response,
    } = useApiGetRequestHook(ORGANIZATION_URL);

    useEffect(() => {
        if (isSuccessful) setOrganizations(response.data.results);
    }, [isCompleted, isSuccessful, response]);

    const StyleCap = {
        textAlign: "center",
        marginTop: "17px",
        fontWeight: "bold"
    }
    return (
        <PageWrapper>
            <div className="Cap" style={StyleCap}>
                Добавить слайд в обработку
            </div>
            <Box sx={{ width: "70%", margin: "0 auto" }}>
                <OrganizationSelect
                    organization={organization}
                    organizations={organizations}
                    setOrganization={setOrganization}
                />
                <TissueSelect tissue={tissue} setTissue={setTissue} />
                <Icd10Modal icd10={icd10} setIcd10={setIcd10}/>
                <FilesUpload
                    status={status}
                    organization={organization}
                    icd10={icd10}
                    tissue={tissue}
                    typeObj={typeObj}
                    versionModel={versionModel}
                    setStatus={setStatus}
                />
            </Box>
        </PageWrapper>
    );
}

export default UploadSlide;
