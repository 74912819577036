import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {EXPORT_URL} from "../../../shared/api/urls";
import getApiRequest from "../../../shared/api/getApiRequest";
import {removeAllSelected} from "../../../shared/model/redux/slides-reducer";


export const Export = () => {

    const dispatch = useDispatch();
    const selected = useSelector((store) => store.slides.selected)

    const downloadSlidesHandler = () => {
        const downloadSlidesIds = selected.map((slide) => {
            return slide.id;
        });
        downloadSlidesIds.forEach(async (slideId) => {
            const url = `${EXPORT_URL}${slideId}/`;
            const response = await getApiRequest(url);
            const {data} = response;
            const str = JSON.stringify(data);
            const type = response.headers["content-type"];
            const fileName = response.headers["content-disposition"]
                .split("filename=")[1]
                .split(";")[0];
            const blob = new Blob([str], {
                type: type,
                encoding: "UTF-8",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        });
        dispatch(removeAllSelected());
    };

    if (selected.length === 0) return (<p>Список для экспорта - пуст</p>)

    return (
        <>
            <ul style={{listStyle: 'none', padding: 0, margin: 0}}>
                {selected.map((item, index) => (
                    <li key={item.id + index} style={{marginBottom: '10px'}}>{item.name}</li>
                ))}
            </ul>
            <Button
                color="primary"
                variant="contained"
                size="medium"
                style={{marginTop: '10px'}}
                onClick={downloadSlidesHandler}>Скачать</Button>
        </>
    )

}
