import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./redux/auth-reducer";
import slideReducer from "./redux/slide-reducer";
import slidesReducer from "./redux/slides-reducer"
import {rehydrateStore} from "./auth/middleware";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        slide: slideReducer,
        slides: slidesReducer,
    },
    preloadedState: rehydrateStore(),

});
