import React from "react";
import ailogo from 'assets/img/ai_logo.png'

const Header = () => (
    <div className="logo">
        <img style={{width: "320px"}} src={ailogo} alt=""/>
    </div>
);

export default Header;
