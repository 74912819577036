export const HEADERS = [
    "Мед. организация",
    "Код МКБ-10",
    "Локализация",
    "Патология",
    "Имя файла",
    "Разметка",
    "Дата обработки",
    "Статус"
];
