import {baseApiInstance} from "./baseApiInstance";

const patchApiRequest = async (url, data = {}, config = {}) => {
    const response = await baseApiInstance
        .patch(url, data, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });

    return response;
};

export default patchApiRequest;
