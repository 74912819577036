import {baseApiInstance} from "./baseApiInstance";

const getApiRequest = async (url) => {
    const response = await baseApiInstance
        .get(url)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });

    return response;
};

export default getApiRequest;
