import React from "react";
import {useEffect} from "react";
import {useParams} from "react-router-dom";

import OpenSeaDragonViewer from "components/OpenSeaDragonViewer";

import PageWrapper from "components/PageWrapper";
import Loader from "../Home/molecules/loader";
import {fetchSlideById} from "../../shared/model/redux/slide-reducer";
import {useDispatch, useSelector} from "react-redux";

function ImageView() {

    const {slideId} = useParams();
    const dispatch = useDispatch();
    const loading = useSelector((store) => store.slide.loading)
    const isError = useSelector((store) => store.slide.isError)

    useEffect(() => {
        dispatch(fetchSlideById(slideId))
    }, [dispatch, slideId])

    return (
        <PageWrapper>
            {loading && (<Loader/>)}
            {!loading && !isError && (<OpenSeaDragonViewer />)}
            {isError && (<h3>Этого слайда не существует</h3>)}
        </PageWrapper>
    );
}

export default ImageView;
