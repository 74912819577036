import React from "react";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Container from "@mui/material/Container";

import {HEADERS} from "../constants";

function TableWithPagination({data, setIcd10, setOpen}) {
    const {results: rows} = data;

    const handleClick = (row) => {
        setIcd10(row);
        setOpen(false);
    };

    return (
        <Container sx={{padding: "24px"}}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {HEADERS.map((header, index) => (
                            <TableCell key={index}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {row.code}
                            </TableCell>
                            <TableCell>{row.diagnosis}</TableCell>
                            <TableCell>
                                <Button onClick={() => handleClick(row)} color="inherit">
                                    Выбрать
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    );
}

export default TableWithPagination;
