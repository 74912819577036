import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {SLIDES_URL} from "shared/api/urls";
import {baseApiInstanceWithRefresh} from "../../api/baseApiInstance.js";

export const fetchSlidesByPage = createAsyncThunk(
    'slides/fetchSlidesByPage',
    (requestParams) => {
        return baseApiInstanceWithRefresh
            .get(`${SLIDES_URL}?page=${requestParams.page}&search=${requestParams.search}`)
            .then(data => {
                return data.data
            })
    })


const initialState = {
    loading: false,
    isError: false,
    error: '',
    data: [],
    selected: [],
    reset: false
};

export const slidesSlice = createSlice({
    name: "slides",
    initialState: initialState,
    reducers: {
        addSelected: (state, action) => {
            state.selected.push(action.payload);
        },
        removeSelected: (state, action) => {
            console.log('Index to remove: ', action.payload)
            state.selected = [...state.selected.filter((item) => item.id !== action.payload)];
        },
        removeAllSelected: (state) => {
            state.reset = true;
            state.selected = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSlidesByPage.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchSlidesByPage.fulfilled, (state, action) => {
                state.loading = false
                state.isError = false
                state.data = action.payload
                state.error = ''
            })
            .addCase(fetchSlidesByPage.rejected, (state, action) => {
                state.loading = false
                state.isError = true
                state.data = []
                state.error = action.error.message
            })
    }
});


// Action creators are generated for each case reducer function
export const {addSelected, removeSelected, removeAllSelected} = slidesSlice.actions;

export default slidesSlice.reducer;
