import React from 'react'
import {useState, useEffect} from "react";
import useApiGetRequestHook from "shared/api/getRequestHook";
import {USER_URL} from 'shared/api/urls';


export function UserCreds() {

    const [data, setData] = useState(null);

    const {isCompleted, isSuccessful, isFailed, response} =
        useApiGetRequestHook(USER_URL);

    useEffect(() => {
        if (isSuccessful) setData(response.data);
    }, [isCompleted, isSuccessful, response]);

    return (
        <div style={{fontSize: "16px", marginLeft: "10px"}}>
            {data && <div>{data.first_name} {data.surname} </div>}
            {isFailed && <h3>Ошибка загрузки</h3>}
        </div>
    );
}

export default UserCreds;
