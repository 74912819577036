export const HEADERS = ["Код", "Диагноз", ""];

const ACCEPTED_FILE_TYPES = [];

const UPLOADING_STATUS = {
    Initialization: "initialization",
    Loading: "loading",
    Success: "success",
    Processing: "processing",
    UploadingError: "uploadingError",
};

const UPLOADING_STATUS_MESSAGE = {
    [UPLOADING_STATUS.Initialization]: {
        message:
            'Добавьте файлы для загрузки с помощью кнопки "Выбрать файлы" или перетащите мышкой в область "Выбранные файлы"',
        color: "text.primary",
    },
    [UPLOADING_STATUS.Loading]: {
        message: "Выполняется загрузка...",
        color: "text.primary",
    },
    [UPLOADING_STATUS.Success]: {
        message: "Загрузка успешно завершена!",
        color: "success.main",
    },
    [UPLOADING_STATUS.Processing]: {
        message: "Файлы обрабатываются, не закрывайте страницу!",
        color: "error.main",
    },
    [UPLOADING_STATUS.UploadingError]: {
        message: "Загрузка завершилась с ошибкой!",
        color: "error.main",
    },
};

const DEFAULT_UPLOAD_QUEUE = {
    completed: [],
    loading: [],
    waiting: [],
};

export {
    ACCEPTED_FILE_TYPES,
    UPLOADING_STATUS,
    UPLOADING_STATUS_MESSAGE,
    DEFAULT_UPLOAD_QUEUE,
};
