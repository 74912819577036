import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const RemoveItemDialog = ({
                              index,
                              fileName,
                              removeFile,
                              removeDialogOpen,
                              setRemoveDialogOpen,
                          }) => {
    const handleClose = () => {
        setRemoveDialogOpen(false);
    };
    const handleAccept = () => {
        removeFile(index);
        setRemoveDialogOpen(false);
    };

    return (
        <div>
            <Dialog
                open={removeDialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Подтвердите удаление</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Имя файла {fileName}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отменить
                    </Button>
                    <Button onClick={handleAccept} color="primary" autoFocus>
                        Подтвердить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default RemoveItemDialog;
