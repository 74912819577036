import React, {useState, useEffect, forwardRef} from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";

import {SLIDES_URL, EXPORT_URL} from "shared/api/urls";
import useApiGetRequestHook from "shared/api/getRequestHook";
import getApiRequest from "shared/api/getApiRequest";
import TableWithPagination from "./TableWithPagination";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ExportSlidesModal() {
    const [open, setOpen] = useState(false);
    const [selectedSlides, setSelectedSlides] = useState([]);

    const [url, setUrl] = useState(SLIDES_URL);
    const [requestParams, setRequestParams] = useState({
        page: 1,
        search: null,
    });
    const [data, setData] = useState({});

    useEffect(() => {
        const {search} = requestParams;
        let newUrl = `${SLIDES_URL}?page=${requestParams.page}`;

        if (search && search.length !== 0) {
            newUrl = newUrl + `&search=${search}`;
        }

        setUrl(newUrl);
    }, [requestParams]);

    const {isCompleted, isSuccessful, isFailed, response} = useApiGetRequestHook(url);

    useEffect(() => {
        if (isSuccessful) setData(response.data);
    }, [isCompleted, isSuccessful, response]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event, value) => {
        setRequestParams({...requestParams, page: value});
    };

    const setSearch = (e) => {
        const {value} = e.target;
        setRequestParams({...requestParams, page: 1, search: value});
    };

    const downloadHandler = () => {
        const downloadSlidesIds = selectedSlides.map((slide) => {
            return slide.id;
        });
        downloadSlidesIds.forEach(async (slideId) => {
            const url = `${EXPORT_URL}${slideId}/`;
            const response = await getApiRequest(url);
            const {data} = response;
            const str = JSON.stringify(data);
            const type = response.headers["content-type"];
            const fileName = response.headers["content-disposition"]
                .split("filename=")[1]
                .split(";")[0];
            const blob = new Blob([str], {
                type: type,
                encoding: "UTF-8",
            });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        });
    };

    const dataLength = Object.keys(data).length;

    return (
        <>
            {isSuccessful && dataLength > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: 1,
                    }}
                >
                    <Button variant="outlined" onClick={handleClickOpen}>
                        Открыть окно выбора слайдов
                    </Button>
                    <Dialog
                        fullScreen
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar
                            sx={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Toolbar>
                                <Typography
                                    sx={{mr: 2, flex: 1}}
                                    variant="h6"
                                    component="div"
                                >
                                    Слайд
                                </Typography>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <TextField
                                id="search"
                                label="Введите имя слайда для поиска"
                                variant="standard"
                                sx={{width: "80%", margin: 1}}
                                value={requestParams.search}
                                onChange={(e) => setSearch(e)}
                            />
                            <TableWithPagination
                                data={data}
                                selectedSlides={selectedSlides}
                                setSelectedSlides={setSelectedSlides}
                            />
                            <Pagination
                                count={data.total_pages}
                                onChange={handleChange}
                                sx={{mb: 3}}
                            />
                        </Box>
                    </Dialog>
                    <Typography
                        component="h3"
                        variant="h6"
                        draggable={false}
                        sx={{mr: 1, marginTop: "15px"}}
                    >
                        Выбранные слайды:
                    </Typography>
                    {selectedSlides.length > 0 && (
                        <>
                            {selectedSlides.map((slide) => (
                                <Box key={slide.id} sx={{p: 1}}>
                                    {slide.name}
                                </Box>
                            ))}
                            <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={downloadHandler}
                            >
                                Скачать geojson
                            </Button>
                        </>
                    )}
                </Box>
            )}
            {isFailed && <h3>Ошибка загрузки</h3>}
        </>
    );
}

export default ExportSlidesModal;
